import React, { FC, useEffect, useState } from 'react';
import { Query, QueryResult, useMutation } from 'react-apollo';
import './Contact.scss';
import 'material-design-icons/iconfont/material-icons.css';
import { Loading, Textfield } from '../_common';
import SearchableDropdown from '../_common/SearchableDropdown/SearchableDropdown';
import { Row, Col } from 'react-bootstrap';
import { CONTACT } from '../../graphql/client/mutations';
import toaster from 'toasted-notes';
import { Alert } from 'react-bootstrap';
import { ContactFileInput, ContactInfo } from '../../types/schemaTypes';
import { Console } from 'console';

interface Option {
    id: number | string;
    name: string | number;
}

const toasterOptions = { duration: 2500 };

const Contact: FC = (): JSX.Element => {
    document.title = 'Contact';
    const maxFileSize = 5 * 1024 * 1024;
    const [imageData, setImageData] = useState<string | ArrayBuffer | null>('');
    const [fileObj, setFileObj] = useState<any>('');
    const [fileUploaded, setFileUploaded] = useState(false);
    const [selectedTitleRole, setSelectedTitleRole] = useState('');
    const [selectedReason, setSelectedReason] = useState('');
    const [selectedReasonEmail, setSelectedReasonEmail] = useState('');
    const [selectedFirstName, setSelectedFirstName] = useState('');
    const [selectedLastName, setSelectedLastName] = useState('');
    const [selectedEmailAddress, setSelectedEmailAddress] = useState('');
    const [selectedTelephone, setSelectedTelephone] = useState('');
    const [selectedComments, setSelectedComments] = useState('');
    const [selectedEntity, setSelectedEntity] = useState('');
    const [selectedEntityEmail, setSelectedEntityEmail] = useState('');
    const [phoneNumberError, setPhoneNumberError] = useState('');
    const [emailErrorMessage, setEmailErrorMessage] = useState('');
    const [fileErrorMessage, setFileErrorMessage] = useState('');
    const [reasonErrorMessage, setReasonFileErrorMessage] = useState('');
    const [count, setCount] = React.useState(1000);

    const reasonTypeoptions: Option[] = [];
    const entityTypeoptions: Option[] = [];

    entityTypeoptions.push({
        id: 'ogcredit@ussilica.com',
        name: 'USS Energy Co',
    });
    entityTypeoptions.push({
        id: 'Customerservice@epminerals.com,ar@epminerals.com',
        name: 'EP MINERALS – North America',
    });
    entityTypeoptions.push({
        id: 'Exportcustomerservice@epminerals.com',
        name: 'EP MINERALS – Outside North America',
    });
    entityTypeoptions.push({
        id: 'customerservice@epengineeredclays.com',
        name: 'EP ENGINEERED CLAYS CORP',
    });

    reasonTypeoptions.push({
        name: 'New Customer Application',
        id: 'usscredit@ussilica.com',
    });

    reasonTypeoptions.push({
        name: 'Support for New Customer Application',
        id: 'usscredit@ussilica.com',
    });
    reasonTypeoptions.push({
        name: 'Change of Address Information',
        id: 'ogcustomermaster@ussilica.com',
    });
    reasonTypeoptions.push({
        name: 'Order Management Inquiry',
        id: 'ogservice@ussilica.com',
    });
    reasonTypeoptions.push({
        name: 'Request for Open Statement of Account',
        id: 'usscredit@ussilica.com',
    });
    reasonTypeoptions.push({
        name: 'Payment or Remittance Inquiry',
        id: 'credit@ussilica.com',
    });
    reasonTypeoptions.push({
        name: 'Dispute an Invoice',
        id: 'ogservice@ussilica.com,usscredit@ussilica.com',
    });
    reasonTypeoptions.push({
        name: 'Customer portal inquiry',
        id: 'ogservice@ussilica.com',
    });
    reasonTypeoptions.push({
        name: 'Other',
        id: 'ogservice@ussilica.com',
    });

    const setEntityStatus = (): boolean => {
        const isNameValid = selectedEntity.trim().length > 0 ? true : false;
        const isFormValid = isNameValid;
        return !isFormValid;
    };

    const setSaveButtonStatus = (): boolean => {
        const isNameValid = selectedFirstName.trim().length > 0 ? true : false;
        const isLastNameValid = selectedLastName.trim().length > 0 ? true : false;
        const isEmailValid = validateEmail(selectedEmailAddress);
        const isRoleTitleValid = selectedTitleRole.trim().length > 0 ? true : false;
        const isTelephoneVaid = selectedTelephone.trim().length > 0 ? true : false;
        const isEntityValid = selectedEntity.trim().length > 0 ? true : false;
        const isReasonValid = selectedReason.trim().length > 0 ? true : false;

        const isFormValid =
            isNameValid &&
            isLastNameValid &&
            isEmailValid &&
            isRoleTitleValid &&
            isTelephoneVaid &&
            isEntityValid &&
            isReasonValid;

        return !isFormValid;
    };

    const onCancel = (): void => {
        //setMutationError('');
        window.history.back();
    };

    const validateEmail = (email: string): boolean => {
        const emailRegex = /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+\.[A-Za-z]+$/;
        //const emailRegex = /\S+@\ussilica|sandboxlogistics+\.\S+/;
        const isEmailValid = emailRegex.test(email.toString());
        return isEmailValid;
    };

    const changeReason = (value): void => {
        if (value !== '' && value !== 0) {
            const reason = getReasonName(value).toString();
            if (reason == '') {
                setReasonFileErrorMessage('Select Reason from Dropdown');
            } else {
                setReasonFileErrorMessage('');
            }
            setSelectedReason(reason);
            if (selectedEntity == 'USS Energy Co') {
                setSelectedEntityEmail(value);
            }
        }
    };

    const changeFirstName = (event: any): void => {
        setSelectedFirstName(event.target.value);
    };

    const changeLastName = (event: any): void => {
        setSelectedLastName(event.target.value);
    };

    const changeRoleTitle = (event: any): void => {
        setSelectedTitleRole(event.target.value);
    };

    const validatePhoneNumber = (value: any): void => {
        let phoneNumberError = '';
        if (value && !/^\d+$/.test(value)) {
            phoneNumberError = 'This field should only contain numeric values.';
        } else if (value && value.length > 10) {
            phoneNumberError = 'The number of characters must not exceed 10 digits.';
        }
        setPhoneNumberError(phoneNumberError);
    };

    const changeTelephone = (event: any): void => {
        setSelectedTelephone(event.target.value);
    };

    const changeEmail = (value: any): void => {
        const emailAddress = value ? value : '';
        setSelectedEmailAddress(emailAddress.trim());
    };

    const changeComments = (event: any): void => {
        setSelectedComments(event.target.value);
        setCount(1000 - event.target.value.length);
    };

    const onSelectEntity = (event: any): void => {
        setSelectedEntity(getEntityName(event.target.value).toString());
        setSelectedEntityEmail(event.target.value);
    };

    const handleOnSave = async (
        entity: string,
        entityEmail: string,
        reason: string,
        firstName: string,
        lastName: string,
        emailAddress: string,
        titleRole: string,
        telephone: string,
        comments: string,
        contactFileInput: ContactFileInput,
    ): Promise<void> => {
        const variables = {
            input: {
                entity,
                entityEmail,
                reason,
                firstName,
                lastName,
                emailAddress,
                titleRole,
                telephone,
                comments,
                contactFileInput,
            },
        };
        await ContactMethod({ variables }).then(data => {
            if (data.data?.contactInfo?.emailSent) {
                setTimeout(function() {
                    window.location.reload();
                }, 1500);
            }
        });
    };

    const withFile = (): void => {
        const reader = new FileReader();
        const file = fileObj;
        reader.onload = async () => {
            const fileName = file.name;
            const imageData = reader.result ? reader.result.toString() : '';
            const contactFileInput: ContactFileInput = {
                fileName: fileName,
                imageData: imageData.split(',')[1],
            };
            handleOnSave(
                selectedEntity,
                selectedEntityEmail,
                selectedReason,
                selectedFirstName,
                selectedLastName,
                selectedEmailAddress,
                selectedTitleRole,
                selectedTelephone,
                selectedComments,
                contactFileInput,
            );
        };
        reader.readAsDataURL(file);
    };

    const withOutFile = (): void => {
        const contactFileInput: ContactFileInput = {};
        handleOnSave(
            selectedEntity,
            selectedEntityEmail,
            selectedReason,
            selectedFirstName,
            selectedLastName,
            selectedEmailAddress,
            selectedTitleRole,
            selectedTelephone,
            selectedComments,
            contactFileInput,
        );
    };

    const onClickSubmit = (): void => {
        if (fileObj == '' || fileObj == null) {
            withOutFile();
        } else {
            withFile();
        }
    };

    const getEntityName = (id: string | number): string | number => {
        const filteredEntity = entityTypeoptions.filter((item: Option) => item.id === id);
        const entity = filteredEntity[0].name;
        return entity;
    };

    const getReasonName = (id: string | number): string | number => {
        const filteredReason = reasonTypeoptions.filter((item: Option) => item.id === id);
        if (filteredReason.length > 0) {
            const reason = filteredReason[0].name;
            return reason;
        } else return '';
    };

    const renderToast = (message, type = 'info') => {
        interface ToastProps {
            onClose?: () => void;
        }
        const Comp = (props: ToastProps) => {
            return (
                <Alert variant={type} onClose={props.onClose}>
                    {message}
                </Alert>
            );
        };

        Comp.displayName = 'toast';
        return Comp;
    };

    const handleLoadFileChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        if (!event || !event.target || !event.target.files) return;
        const file = event.target.files[0];
        setFileObj(file);
        if (file.size > maxFileSize) {
            setFileErrorMessage('File size exceeds the limit of 5 MB');
            setFileObj('');
        }
        event.target.value = '';
    };

    const onCompletedContacting = (data: ContactInfo): void => {
        if (data.contactInfo === null) return;
        const saveUserErrors = data.contactInfo.errors;
        const emailStatus = data.contactInfo.emailSent;
        if (emailStatus) {
            toaster.notify(renderToast('Email sent Succesfully', 'success'), toasterOptions);
        } else if (!emailStatus && saveUserErrors && saveUserErrors.length > 0) {
            setEmailErrorMessage('Unable to Register: ' + saveUserErrors[0]);
            toaster.notify(renderToast('Email not sent Succesful, Please try again', 'danger'), toasterOptions);
        }
        // onCancel();
    };

    const [ContactMethod, { loading: contactLoading }] = useMutation(CONTACT, {
        onCompleted: onCompletedContacting,
    });

    return (
        <div className="LoadFieldOpDetails">
            {contactLoading && (
                <div className="SpinnerContainer">
                    <Loading />
                </div>
            )}
            <div className="R-Header-Container">
                <div className="R-Header-SearchBar">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="row">
                                <div className="col-md-3 my-auto">
                                    <label htmlFor="staticEmail">Entity</label>
                                </div>
                                <div className="col-md-6">
                                    <select
                                        className="form-control"
                                        onChange={e => onSelectEntity(e)}
                                        value={selectedEntityEmail}
                                    >
                                        <option value={0}>-- Select Entity --</option>
                                        {entityTypeoptions.map((customer: any, index) => (
                                            <option key={customer.id?.toString()} value={customer.id?.toString()}>
                                                {customer.name}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3"></div>
                        <div className="col-md-5 text-right">
                            <button
                                className="Button CustomButton-Plain mx-1"
                                onClick={onClickSubmit}
                                disabled={contactLoading ? true : setSaveButtonStatus()}
                            >
                                Submit
                            </button>
                        </div>
                    </div>
                </div>
                <div className="Header-ListHeader"></div>
            </div>
            <div className="ContactPageDetails">
                <div className="ContactPageDetailsContainer">
                    <Row>
                        <Col md={6}>
                            <Textfield
                                label="Entity"
                                name="entity"
                                value={selectedEntity || ''}
                                maxLength={40}
                                disabled={true}
                                required
                            />
                        </Col>
                        <Col md={6} className="Reason-Dropdown">
                            <SearchableDropdown
                                errorMessage={reasonErrorMessage}
                                hasError={reasonErrorMessage !== ''}
                                autoCompleteEnabled={false}
                                dropdownOptions={reasonTypeoptions}
                                id="reason"
                                label="Reason"
                                onSelect={(eventKey: string | null) => {
                                    changeReason(eventKey);
                                }}
                                value={''}
                                required
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6}>
                            <Textfield
                                label="FirstName"
                                value={''}
                                onChange={(event): void => changeFirstName(event)}
                                maxLength={64}
                                required
                            />
                        </Col>
                        <Col md={6}>
                            <Textfield
                                label="LastName"
                                value={''}
                                onChange={(event): void => changeLastName(event)}
                                maxLength={64}
                                required
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6}>
                            <Textfield
                                label="Title/Role"
                                value={''}
                                onChange={(event): void => changeRoleTitle(event)}
                                maxLength={64}
                                required
                            />
                        </Col>
                        <Col md={6}>
                            <Textfield
                                label="Telephone Number"
                                value={''}
                                onChange={(event): void => {
                                    changeTelephone(event);
                                    validatePhoneNumber(event.target.value);
                                }}
                                error={phoneNumberError !== ''}
                                helperText={phoneNumberError}
                                maxLength={10}
                                required
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6}>
                            <Textfield
                                label="Email"
                                helperText={emailErrorMessage}
                                value={''}
                                disabled={false}
                                onChange={event => {
                                    const value = event.currentTarget.value;
                                    changeEmail(value);
                                }}
                                maxLength={64}
                                required
                            />
                        </Col>
                        <Col md={6}>
                            <Textfield
                                label="Comments"
                                value={''}
                                disabled={false}
                                onChange={event => {
                                    changeComments(event);
                                }}
                                maxLength={1000}
                            />
                            <p className={count < 50 ? 'label-rem-red' : 'label-rem'}>
                                {'Characters left: '}
                                {count}
                            </p>
                        </Col>
                    </Row>
                    <Row>
                        <div className="Row ContactPageConfirmation-FileBrowser-Background">
                            <label className="ContactPageConfirmation-FileBrowser-Label">
                                {fileObj ? fileObj.name : 'Click here to choose Attachments'}
                                <input
                                    type="file"
                                    onChange={event => handleLoadFileChange(event)}
                                    className="ContactPageConfirmation-FileBrowser-Input"
                                />
                            </label>
                            <label className="ContactFileWarningMessage">
                                {fileErrorMessage ? fileErrorMessage : ''}
                            </label>
                        </div>
                    </Row>

                    <Row></Row>
                </div>
            </div>
        </div>
    );
};

export default Contact;
