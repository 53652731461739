import 'material-design-icons/iconfont/material-icons.css';
import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useMutation } from 'react-apollo';
import { REGISTER_USER } from '../../../graphql/client/mutations';
import { RegisterUser } from '../../../types/schemaTypes';
import { CustomButton, Loading } from '../../_common';
import SearchableDropdown from '../../_common/SearchableDropdown/SearchableDropdown';
import { Textfield } from '../../_common/Textfield/Textfield';
import './RegisterModal.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import toaster from 'toasted-notes';
import { Alert } from 'react-bootstrap';

interface Option {
    id: number | string;
    name: string | number;
}

interface RegisterModalProps {
    onClickHide: () => void;
    onUserSaved: () => void;
    show: boolean;
    errorMessage?: string;
    setErrorMessage: React.Dispatch<React.SetStateAction<string>>;
}

const toasterOptions = { duration: 2500 };
const RegisterModal = ({
    show,
    onClickHide,
    onUserSaved,
    errorMessage,
    setErrorMessage,
}: RegisterModalProps): JSX.Element => {
    const [selectedAccountType, setselectedAccountType] = useState('');
    const [selectedEntity, setselectedEntity] = useState('');
    const [selectedEntityEmail, setselectedEntityEmail] = useState('');
    const [selectedFirstName, setSelectedFirstName] = useState('');
    const [selectedLastName, setSelectedLastName] = useState('');
    const [selectedTitleRole, setselectedTitleRole] = useState('');
    const [selectedEmailAddress, setSelectedEmailAddress] = useState('');
    const [selectedTelephone, setselectedTelephone] = useState('');

    const [emailExists, setEmailExists] = useState(false);
    const [emailErrorMessage, setEmailErrorMessage] = useState('');
    const [phoneNumberError, setPhoneNumberError] = useState('');

    const accountTypeoptions: Option[] = [];
    const entityTypeoptions: Option[] = [];

    //Will rewrite below code with constansts file
    accountTypeoptions.push({
        id: 'New Account',
        name: 'New Account',
    });
    accountTypeoptions.push({
        id: 'Existing Account',
        name: 'Existing Account',
    });

    entityTypeoptions.push({
        id: ' ogservice@ussilica.com',
        name: 'USS Energy Co',
    });
    entityTypeoptions.push({
        id: 'Customerservice@epminerals.com',
        name: 'EP MINERALS – North America',
    });
    entityTypeoptions.push({
        id: 'Exportcustomerservice@epminerals.com',
        name: 'EP MINERALS – Outside North America',
    });
    entityTypeoptions.push({
        id: 'customerservice@epengineeredclays.com',
        name: 'EP ENGINEERED CLAYS CORP',
    });

    const clearState = (): void => {
        setselectedAccountType('');
        setselectedEntity('');
        setselectedEntityEmail('');
        setselectedTitleRole('');
        setSelectedFirstName('');
        setSelectedLastName('');
        setSelectedEmailAddress('');
        setSelectedEmailAddress('');
    };

    const onCompletedSaveUser = (data: RegisterUser): void => {
        if (data.registerUser === null) return;
        const saveUserErrors = data.registerUser.errors;
        const emailStatus = data.registerUser.emailSent;
        if (emailStatus) {
            toaster.notify(renderToast('Registration Email sent Succesfully', 'success'), toasterOptions);
        } else if (!emailStatus && saveUserErrors && saveUserErrors.length > 0) {
            setEmailErrorMessage('Unable to Register: ' + saveUserErrors[0]);
            toaster.notify(renderToast('Registration Not Succesful, Please try again', 'danger'), toasterOptions);
        }
        onCancel();
    };

    const renderToast = (message, type = 'info') => {
        interface ToastProps {
            onClose?: () => void;
        }
        const Comp = (props: ToastProps) => {
            return (
                <Alert variant={type} onClose={props.onClose}>
                    {message}
                </Alert>
            );
        };

        Comp.displayName = 'toast';
        return Comp;
    };

    const [RegisterUser, { loading: registerUserLoading }] = useMutation(REGISTER_USER, {
        onCompleted: onCompletedSaveUser,
    });

    const handleOnSave = async (
        accountType: string,
        entity: string,
        entityEmail: string,
        firstName: string,
        lastName: string,
        emailAddress: string,
        titleRole: string,
        telephone: string,
    ): Promise<void> => {
        const variables = {
            input: {
                accountType,
                entity,
                entityEmail,
                firstName,
                lastName,
                emailAddress,
                titleRole,
                telephone,
            },
        };
        await RegisterUser({ variables });
    };

    const onClickSave = (): void => {
        handleOnSave(
            selectedAccountType,
            selectedEntity,
            selectedEntityEmail,
            selectedFirstName,
            selectedLastName,
            selectedEmailAddress,
            selectedTitleRole,
            selectedTelephone,
        );
    };

    const onReset = (): void => {
        if (!emailExists) {
            handleOnSave(
                selectedAccountType,
                selectedEntity,
                selectedEntityEmail,
                selectedFirstName,
                selectedLastName,
                selectedEmailAddress,
                selectedTitleRole,
                selectedTelephone,
            );
        }
    };

    const onCancel = (): void => {
        clearState();
        onClickHide();
    };

    const validateEmail = (email: string): boolean => {
        const emailRegex = /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+\.[A-Za-z]+$/;
        //const emailRegex = /\S+@\ussilica|sandboxlogistics+\.\S+/;
        const isEmailValid = emailRegex.test(email.toString());
        return isEmailValid;
    };

    const setSaveButtonStatus = (): boolean => {
        if (emailExists) return true;
        const isNameValid = selectedFirstName.trim().length > 0 ? true : false;
        const isLastNameValid = selectedLastName.trim().length > 0 ? true : false;
        const isEmailValid = validateEmail(selectedEmailAddress);
        const isRoleTitleValid = selectedTitleRole.trim().length > 0 ? true : false;
        const isTelephoneVaid = selectedTelephone.trim().length > 0 ? true : false;
        const isAccountTypeValid = selectedAccountType.trim().length > 0 ? true : false;
        const isEntityValid = selectedEntity.trim().length > 0 ? true : false;

        const isFormValid =
            isNameValid &&
            isLastNameValid &&
            isEmailValid &&
            isRoleTitleValid &&
            isTelephoneVaid &&
            isEntityValid &&
            isAccountTypeValid;

        return !isFormValid;
    };

    const changeFirstName = (event: any): void => {
        setSelectedFirstName(event.target.value);
    };

    const changeLastName = (event: any): void => {
        setSelectedLastName(event.target.value);
    };

    const changeRoleTitle = (event: any): void => {
        setselectedTitleRole(event.target.value);
    };

    const validatePhoneNumber = (value: any): void => {
        let phoneNumberError = '';
        if (value && !/^\d+$/.test(value)) {
            phoneNumberError = 'This field should only contain numeric values.';
        } else if (value && value.length > 10) {
            phoneNumberError = 'The number of characters must not exceed 10 digits.';
        }
        setPhoneNumberError(phoneNumberError);
    };

    const changeTelephone = (event: any): void => {
        setselectedTelephone(event.target.value);
    };

    const changeEntity = (value: any): void => {
        setselectedEntity(getEntityName(value).toString());
        setselectedEntityEmail(value);
    };

    const getEntityName = (id: string | number): string | number => {
        const filteredRole = entityTypeoptions.filter((item: Option) => item.id === id);
        const entityName = filteredRole[0].name;
        return entityName;
    };

    const changeAccountType = (value: any): void => {
        setselectedAccountType(value);
    };

    const changeEmail = (value: any): void => {
        if (errorMessage) {
            setErrorMessage('');
        }
        const emailAddress = value ? value : '';
        setSelectedEmailAddress(emailAddress.trim());
    };

    const checkExternalUser = (value: any): void => {
        const emailRegex = /\S+@\ussilica+\.\S+/;
        const isUssilicaMail = emailRegex.test(value.toString());
    };

    return (
        <Modal show={show} onHide={onCancel} size="lg">
            <Modal.Header className="RegisterModal-Header">
                <Modal.Title className="RegisterModal-Header-Container">
                    <button className="material-icons RegisterModal-X-Button" onClick={onCancel}>
                        close
                    </button>
                    <div className="RegisterModal-Title">{'Welcome'}</div>
                    <div className="RegisterModal-ErrorMessage">{errorMessage}</div>
                </Modal.Title>
            </Modal.Header>

            <Modal.Body>
                {registerUserLoading && (
                    <div className="RegisterModal-SpinnerContainer">
                        <Loading />
                    </div>
                )}
                <div className="RegisterModal-Down-TextField-Container">
                    <div className="RegisterModal-Textfield">
                        <SearchableDropdown
                            dropdownOptions={accountTypeoptions}
                            id="accountType"
                            label="Account Type"
                            onSelect={(eventKey: string | null) => {
                                changeAccountType(eventKey);
                            }}
                            value={''}
                        />
                    </div>
                    <div className="RegisterModal-Textfield">
                        <SearchableDropdown
                            dropdownOptions={entityTypeoptions}
                            id="entity"
                            label="Entity"
                            onSelect={(eventKey: string | null) => {
                                changeEntity(eventKey);
                            }}
                            value={''}
                        />
                    </div>
                </div>
                <div className="RegisterModal-Upper-TextField-Container">
                    <div className="RegisterModal-Textfield">
                        <Textfield
                            label="FirstName"
                            value={''}
                            onChange={(event): void => changeFirstName(event)}
                            maxLength={64}
                        />
                    </div>
                    <div className="RegisterModal-Textfield">
                        <Textfield
                            label="LastName"
                            value={''}
                            onChange={(event): void => changeLastName(event)}
                            maxLength={64}
                        />
                    </div>
                </div>
                <div className="RegisterModal-Upper-TextField-Container">
                    <div className="RegisterModal-Textfield">
                        <Textfield
                            label="Title"
                            value={''}
                            onChange={(event): void => changeRoleTitle(event)}
                            maxLength={64}
                        />
                    </div>
                    <div className="RegisterModal-Textfield">
                        <Textfield
                            label="Telephone Number"
                            value={''}
                            onChange={(event): void => {
                                changeTelephone(event);
                                validatePhoneNumber(event.target.value);
                            }}
                            error={phoneNumberError !== ''}
                            helperText={phoneNumberError}
                            maxLength={10}
                        />
                    </div>
                </div>
                <div className="RegisterModal-Down-TextField-Container">
                    <div className="RegisterModal-Textfield">
                        <Textfield
                            label="Email"
                            error={emailExists == null ? false : emailExists}
                            helperText={emailErrorMessage}
                            value={''}
                            disabled={false}
                            onChange={event => {
                                const value = event.currentTarget.value;
                                if (value) checkExternalUser(value);
                                changeEmail(value);
                            }}
                            maxLength={64}
                        />
                    </div>
                </div>
            </Modal.Body>

            <Modal.Footer className="RegisterModal-Footer">
                <CustomButton
                    title="Submit"
                    isDisabled={errorMessage || registerUserLoading ? true : setSaveButtonStatus()}
                    onClick={onClickSave}
                ></CustomButton>
            </Modal.Footer>
        </Modal>
    );
};

export default RegisterModal;
